import { environment } from "src/environments/environment";

export const base_url = environment.url_backend;
//export const base_url = 'https://coredev.bon-aventure.com';
//export const base_url = 'http://localhost/n360-backend';

export const ListUrl = {
    baseUrl: base_url,
    login_url: `${base_url}/login`,
    api: `${base_url}/aplication`,
    register_company: `${base_url}/register/empresa`,
    update_sucursal: `${base_url}/register/update_data`,
    register_aplication: `${base_url}/register/add_aplication`,
    update_compnay: `${base_url}/register/update_compnay`,
    register_sucursal: `${base_url}/register`,
    get_companys: `${base_url}/empresa/consultar_empresas`,
    get_aplications: `${base_url}/aplicaciones/leer`,
    get_users: `${base_url}/usuario/get_users`,
    get_countrys: `${base_url}/usuario/get_countrys`,
    get_rols: `${base_url}/usuario/get_rols`,
    get_actions: `${base_url}/usuario/get_actions`,
    get_modulos: `${base_url}/usuario/get_modulos`,
    create_user: `${base_url}/register/create_user`,
    update_user: `${base_url}/register/update_user`,
    remove_user: `${base_url}/register/remove_user`,
    create_rol: `${base_url}/register/create_rol`,
    remove_rol: `${base_url}/register/remove_rol`,
    delete_collection:  `${base_url}/vel_adm_cobranzas/rechazar`,
    approve_collection:  `${base_url}/vel_adm_cobranzas/aprobar`,
    get_all_collection: `${base_url}/vel_adm_cobranzas/obtener_todos`,
    get_all_collection_adm: `${base_url}/vel_adm_cobranzas/obtener_todos_adm`,
    pending_collection: `${base_url}/vel_adm_cobranzas/pendiente`,
    pending_historial_collection: `${base_url}/vel_adm_cobranzas/pendiente_historial`,
    review_collection: `${base_url}/vel_adm_cobranzas/enrevision`,
    getClientes_get: `${base_url}/vel/clientes/getclientes`,
    obtener_planificaciones: `${base_url}/planificacion/obtener_get`,
    obtener_planvisita: `${base_url}/planvisita/obtener_planvisita`,
    obtener_cuentascobrar: `${base_url}/cuentas/obtener_cuentascobrar`,
    obtener_tipo_cobro: `${base_url}/cuentas/obtener_tipo_cobro`,
    obtener_tipoclientes: `${base_url}/clientes/obtener_tipoclientes`,
    obtener_bancos_nacionales: `${base_url}/bancos/obtener_nacionales`,
    obtener_bancos_privados: `${base_url}/bancos/obtener_privados`,
    obtener_bancos_todos: `${base_url}/bancos/obtener_todos`,
    obtener_filtros: `${base_url}/filtros/obtener_filtros`,
    obtener_motivo_visita: `${base_url}/motivos/obtener_motivo_visita`,
    obtener_productos: `${base_url}/productos/obtener_productos`,
    obtener_pedidos: `${base_url}/vel_adm_pedidos/obtener`,
    eliminar_pedido: `${base_url}/vel_adm_pedidos/eliminar`,
    eliminar_varios_pedidos: `${base_url}/vel_adm_pedidos/eliminarvarios`,
    aprobar_todos_pedidos: `${base_url}/vel_adm_pedidos/aprobar`,
    aprobar_varios_pedidos: `${base_url}/vel_adm_pedidos/aprobarvarios`,
    //obtener_grafico_pedidos: `${base_url}/bona_sales_pedidos/obtenergrafico`,
    hed_nestle: `${base_url}/head/nestle/reporte`,
    check_internet: `${base_url}/aplication/check`,
    desactivar_user: `${base_url}/register/desactivar_user`,
    //Vel Adm Configuraciones
    vel_adm_config: `${base_url}/vel_adm_config/obtener`,
    vel_adm_config_set: `${base_url}/vel_adm_config/actualizar_rutas`,
    guardar_bancos: `${base_url}/vel_adm_config/guardar_bancos`,
    obtener_bancos: `${base_url}/vel_adm_config/obtener_bancos`,
    sync_bancos: `${base_url}/vel_adm_config/sync_bancos`,
    sync_autoventa: `${base_url}/vel_adm_config/sync_autoventa`,
    sync_geo_local: `${base_url}/vel_adm_config/sync_geo_local`,
    //Usuario
    get_user_apps_modulos: `${base_url}/usuario/get_user_apps_modulos`,
    get_user_rutas: `${base_url}/usuario/get_user_rutas`,
    //planificacion: `${base_url}/bona_sales_planificacion/obtener_clientes`,
    //planificacion_producto: `${base_url}/bona_sales_planificacion/obtener_productos_todos`,
    getPlanVisita: `${base_url}/vel_adm_planvisita/plan_de_visita`,
    setPedidos: `${base_url}/erp_api/pedidos/setpedidos`,
    setVisitas: `${base_url}/erp_api/visitas/setvisitas`,
    send_cobranzas: `${base_url}/erp_api/cobranzas/send_cobranzas`,
    get_data_ra: `${base_url}/reporte_activacion/get_data`,
    get_reporte_activacion: `${base_url}/reporte_activacion/reporte`,
    get_analisis_vencimiento: `${base_url}/analisis_vencimiento/reporte`,
    get_ventas_diarias: `${base_url}/get_ventas_diarias`,
    get_supervisores: `${base_url}/get_supervisores`,
    get_filtros_av: `${base_url}/get_filtros_av`,
    get_tipocxc: `${base_url}/get_tipocxc`,
    getDescuentos: `${base_url}/descuentos/get_descuentos`,
    crear_grupo: `${base_url}/notificacion/grupo/crear`,
    actualizar_usuario_grupo: `${base_url}/notificacion/grupo/actualizarUsuarios`,
    eliminar_usuario_grupo: `${base_url}/notificacion/grupo/eliminarUsuarios`,
    eliminar_grupo: `${base_url}/notificacion/grupo/eliminarGrupo`,
    crear_notificacion: `${base_url}/notificacion/grupo/crearNotificacion`,
    nuevas_notificaciones: `${base_url}/notificacion/grupo/nuevasNotificaciones`,
    todas_notificaciones: `${base_url}/notificacion/grupo/todasNotificaciones`,
    todos_grupo: `${base_url}/notificacion/grupo/todosGrupos`,
    crear_notificacion_user: `${base_url}/notificacion/grupo/crear_noti_user`,
    borrar_notificacion: `${base_url}/notificacion/grupo/eliminar_notificacion`,
    notificacion_leidas: `${base_url}/notificacion/grupo/leidas`,
    actualizar_bd: `${base_url}/actualizacion/bases_datos_sucu`,
    get_categorias: `${base_url}/categoria/get_select`,
    get_categoriesCatalogo: `${base_url}/categoria/get`,
    create_category: `${base_url}/categoria/create`,
    update_category: `${base_url}/categoria/update`,
    delete_category: `${base_url}/categoria/delete`,
    set_feedback: `${base_url}/feedback/set`,
    get_feedback: `${base_url}/feedback/get`,
    update_receptor: `${base_url}/feedback/update_receptor`,
    register_msj: `${base_url}/mensajes/register`,
    update_msj: `${base_url}/mensajes/update`,
    delete_msj: `${base_url}/mensajes/delete`,
    get_all_msj: `${base_url}/mensajes/all`,
    get_act_msj: `${base_url}/mensajes/all_act`,
    actualizar_estado_factura: `${base_url}/erp_api/facturas/actualizar`,
    obtener_estado_factura: `${base_url}/erp_api/facturas/obtener`,
    setFacturas: `${base_url}/erp_api/facturas/setfacturas`,
    get_facturas:  `${base_url}/vel_adm_facturas/obtener`,
    eliminar_factura: `${base_url}/vel_adm_facturas/eliminar`,
    aprobar_todos_facturas: `${base_url}/vel_adm_facturas/aprobar`,
    eliminar_varios_facturas: `${base_url}/vel_adm_facturas/eliminarvarios`,
    aprobar_varios_facturas: `${base_url}/vel_adm_facturas/aprobarvarios`,
    event_register: `${base_url}/eventos/registro_evento`,
    event_transacciones: `${base_url}/eventos/event_transacciones`,
    event_modulos: `${base_url}/eventos/modulos_evento`,
    reporte_pedidos: `${base_url}/eventos/reporte_pedidos`,
    get_eventos: `${base_url}/eventos/obtener_eventos`,
    get_estadistica: `${base_url}/eventos/estadistica_general`,
    register_configs: `${base_url}/configs/register`,
    configs_shemes: `${base_url}/configs/shemes`,
    save_fields: `${base_url}/configs/save_fields`,
    update_configs: `${base_url}/configs/update`,
    delete_configs: `${base_url}/configs/delete`,
    get_all_configs: `${base_url}/configs/all`,
    register_suc_configs: `${base_url}/sucursal_configs/register`,
    activa_suc_configs: `${base_url}/sucursal_configs/activa`, 
    inactiva_suc_configs: `${base_url}/sucursal_configs/inactiva`, 
    delete_suc_configs: `${base_url}/sucursal_configs/delete`, 
    get_all_suc_configs: `${base_url}/sucursal_configs/all`,
    verifica_suc_configs: `${base_url}/sucursal_configs/verifica`,
    get_all_suconfig_list: `${base_url}/sucursal_configs/all_list`,
    obtener_planvisita_n360: `${base_url}/planvisita/obtener_planvisita_n360`,
    getclientes_n360: `${base_url}/vel/clientes/getclientes_n360`,
    syncFormats: `${base_url}/decimilesmone/getdecimiles_mone`,
    setFormats: `${base_url}/decimilesmone/setdecimiles`,
    getDatatemp: `${base_url}/datatemp/obtener`,
    setDatatemp: `${base_url}/datatemp/agregar`,
    deleteDatatemp: `${base_url}/datatemp/eliminar`,
    register_plansucursal: `${base_url}/register/register_plansucursal`,
    get_planes: `${base_url}/register/get_planes`,
    update_fecha: `${base_url}/register/update_fecha`,
    geratedatabase: `${base_url}/register/geratedatabase`,
    get_planes_all: `${base_url}/register/get_planes_all`,
    delete_plans: `${base_url}/register/delete_plans`,
    habilitar_plans: `${base_url}/register/habilitar_plans`,
    register_plan: `${base_url}/register/register_plan`,
    reporte_transaccion_sucursal: `${base_url}/estadistica/reporte_transaccion_sucursal`,
    hed_dusa: `${base_url}/hed_dusa/get_reporte`,
    register_trans_adi: `${base_url}/register/register_trans_adi`,
    register_trans: `${base_url}/register/register_trans`,
    get_actions_apps: `${base_url}/usuario/get_actions_apps`,
    get_zona_horaria: `${base_url}/register/get_zona_horaria`,
    hed_colgate: `${base_url}/hed_colgate/get_reporte`,
    init_date: `${base_url}/init_date/obtener`,
    get_date_tz: `${base_url}/init_date/obtener_tz`,
    remove_sucursal: `${base_url}/register/remove_sucursal`,
    remove_empresa: `${base_url}/register/remove_empresa`,
    clear_datas: `${base_url}/register/clear_datas`,
    sql_backup: `${base_url}/register/sql_backup`,
    excel_backup: `${base_url}/register/excel_backup`,
    alert_change: `${base_url}/notificacion/alerta/cambios`,
    get_catcobranzas: `${base_url}/vel/get_catcobranzas`,
    get_catpedidos: `${base_url}/vel/get_catpedidos`,
    get_reporte_log: `${base_url}/reporte/log`,
    get_visitas: `${base_url}/visitas/obtener_visitas`,
    get_productos_inventario: `${base_url}/gestion_almacen/get_productos_inventario`,
    get_depositos_inventario: `${base_url}/gestion_almacen/get_depositos`,
    get_gestion_productos_adm: `${base_url}/gestion_almacen/get_gestion_productos_adm`,
    delete_productos_gestion_almacen_adm: `${base_url}/gestion_almacen/delete_productos_gestion_almacen_adm`,
    aprob_productos_gestion_almacen_adm: `${base_url}/gestion_almacen/aprob_productos_gestion_almacen_adm`,
    detalles_producto_almacen_adm: `${base_url}/gestion_almacen/detalles_producto_almacen_adm`,
    delete_productos_adm: `${base_url}/gestion_almacen/delete_productos_adm`,
    send_orders: `${base_url}/integraciones/nestle/pedidos/send_orders`,
    sync_orders: `${base_url}/integraciones/nestle/pedidos/obtener`,
    update_status_orders: `${base_url}/integraciones/nestle/update_status_orders`,
    get_visitas_adm: `${base_url}/vel_adm_planvisita/get_visitas`,
    sincro_visits: `${base_url}/vel_adm_planvisita/sincro_visits`,
    set_api_config: `${base_url}/integraciones/set_api_config`,
    get_reporte_transaccion: `${base_url}/eventos/get_reporte_transaccion`,
    get_verificar_ciere: `${base_url}/hed_colgate/verificar_cierre`,
    get_config_code_distribuidor: `${base_url}/hed_colgate/config_code_distribuidor`,
    //  CONFIGURACIÓN DINÁMICA
    get_secuencia_visita: `${base_url}/dynamic/config/getSecuenciaVisita`,
    get_frecuencia_visita: `${base_url}/dynamic/config/getFrecuenciaVisita`,
    get_tipo_precio: `${base_url}/dynamic/config/getTipoPrecio`,
    get_condiciones_pago: `${base_url}/dynamic/config/getCondicionesPago`,
    get_rutas: `${base_url}/bona/rutas/get_rutas`,
    get_grupo_cliente: `${base_url}/dynamic/config/getGrupoCliente`,
    get_tipo_cliente: `${base_url}/dynamic/config/getTipoCliente`,
    get_zonas: `${base_url}/dynamic/config/getZonas`,
    get_estados: `${base_url}/dynamic/config/getEstados`,
    get_ciudades: `${base_url}/dynamic/config/getCiudades`,
    get_municipios: `${base_url}/dynamic/config/getMunicipios`,
    sync_productos: `${base_url}/gestion_almacen/set_productos_inventario`,
    //  extractor colgate cierre inventario y diagnostico de datos
    hed_colgate_get_productos: `${base_url}/hed_colgate/get_productos`,
    hed_colgate_cerrar_inventario: `${base_url}/hed_colgate/cierre_inventario`,
    hed_colgate_reconteo_inventario: `${base_url}/hed_colgate/reconteo_inventario`,
    hed_colgate_diagnostico: `${base_url}/hed_colgate/diagnostico`,
    get_grupos: `${base_url}/bona/grupos/todos`,
    consultar_saldo_pedidos: `${base_url}/vel_adm_pedidos/obtener/saldo`,
    consultar_saldo_facturas: `${base_url}/vel_adm_facturas/obtener/saldo`,
    consultar_saldo_cobranzas: `${base_url}/vel_adm_cobranzas/obtener_saldo`,
    obtener_grupos_productos_ruta: `${base_url}/grupos_productos_ruta/obtener`,
    eliminar_grupos_productos_ruta: `${base_url}/grupos_productos_ruta/eliminar`,
    actualizar_grupos_productos_ruta: `${base_url}/grupos_productos_ruta/actualizar`,
    actualizar_grupos_productos_usuario: `${base_url}/grupos_productos_usuario/actualizar`,
    token_sucursal: `${base_url}/register/token_sucursal`,
    aprob_prodcts_conflicts_gest_almacen: `${base_url}/gestion_almacen/aprob_conflicts`,
    get_data_filter_ga: `${base_url}/gestion_almacen/data_filter`,
    get_data_report_ga: `${base_url}/gestion_almacen/data_report`,
    hed_colgate_clean_cierre: `${base_url}/hed_colgate/clean_cierre`,
    
    //IMPRESORA FISCAL
    fiscal_printer_post: `${base_url}/fiscal_printer/post`,
    autoventa_enviar:`${base_url}/vel/send_autoventa`,
    obtener_autoventa:`${base_url}/vel_adm_autoventa/obtener`,
    aprobar_autoventa:`${base_url}/vel_adm_autoventa/aprobar`,

    get_config_unilever:`${base_url}/hed_unilever/get_config`,
    get_report_unilever:`${base_url}/hed_unilever/get_report_unilever`,
    //CONFIGURACION FORMATO AUTOVENTA
    formato_autoventa_obtener: `${base_url}/formato_autoventa/obtener`,
    formato_autoventa_enviar: `${base_url}/formato_autoventa/enviar`,
    ventasdiarias: `${base_url}/reporte_ventas_diarias`,
    anteriorreporte: `${base_url}/anteriorreporte`,
    removehistori: `${base_url}/removehistori`,
    get_report_adm:  `${base_url}/reporte/adm`,
    get_info_relation_users: `${base_url}/dashboard_ventas/get_users_config`,
    get_list_config_users: `${base_url}/dashboard_ventas/get_list_config_users`,
    save_list_config_users: `${base_url}/dashboard_ventas/save_list_config_users`,
    delete_list_config_users: `${base_url}/dashboard_ventas/delete_list_config_users`,
    get_dashborad_data: `${base_url}/dashboard_ventas/get_dashborad_data`,
    get_report_visit_adm: `${base_url}/reporte/adm/visitas`,
    get_data_filter_rsp: `${base_url}/reporte_segmantacion/data_filter`,
    get_data_report_rsp: `${base_url}/reporte_segmantacion/data_report`,
    get_agenda_basica: `${base_url}/clientes/agenda_basica`,//Obtiene una lista basica de los clientes del erp
    get_data_report_rdp: `${base_url}/reporte_diario_pedidos/data_report`,
    get_data_config_precios: `${base_url}/config_precios/get_data`,
    guardar_config_precios: `${base_url}/config_precios/set_data`,
    get_data_config_precios_modal: `${base_url}/config_precios/get_dataM`,
    deleted_data_config_precios: `${base_url}/config_precios/delete_data`,
    get_data_motivos: `${base_url}/motivos_rechazo/get_data`,
    set_data_motivos: `${base_url}/motivos_rechazo/set_data`,
    delete_data_motivos: `${base_url}/motivos_rechazo/delete_data`,
}
